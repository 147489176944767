import React, { useState } from "react";
import { Button, Col, Container, Input, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";

import "../Login.css";
import { setLocalStorageItem } from "../../helpers/LocalStorage";
import apiCaller from "../../helpers/ApiCaller";
import { consts } from "../../helpers/const";
import ErrorModal from '../ErrorModal';

const Index = ({ commonVariable }) => {
  const navigate = useNavigate();

  const { setProfileData } = commonVariable;

  // Define state variables for username and password
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    key: "VotingPage",
  });

  const [error, setError] = useState(null); // State to store error message
  const [modalOpen, setModalOpen] = useState(false); // State to manage modal visibility

    // Function to close the modal
    const toggleModal = () => {
      setModalOpen(!modalOpen);
    };

  // Event handler to update state when input fields change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Event handler to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you can perform actions like login validation, API calls, etc.

    sendOtp();
    // Reset form fields
    setFormData({
      username: "",
      password: "",
    });
  };

  // Function to execute Api Calling
  const sendOtp = async () => {
    try {

      // Ensure key is set in the form data
      const requestData = { ...formData, key: formData.key || "VotingPage" };

      const response = await apiCaller(
        consts.siteUrl + consts.api.authenticate,
        "POST",
        requestData
      );

      const responseData = response;
      if (responseData) {
        if(response.response=== 'Bad Credentials'){
          setError("Wrong user name or pin"); 
          setModalOpen(true);
	} else if(response.response=== 'Already Registered the Vote'){
          setError("Already Registered the Vote"); 
          setModalOpen(true);
        }else{        const { votername, voterid, voteraddress, constituencyno } =
          responseData.userDataResponse;

        // Update the profile data using setProfileData
        setProfileData({
          votername,
          voterid,
          voteraddress,
          constituencyno,
        });

        setLocalStorageItem("userData", responseData.userDataResponse);
        setLocalStorageItem("userVerfier", responseData.token);
        setLocalStorageItem("isLoggedIn", true);
        navigate("demovoting");
      }
    }
    } catch (error) {
      console.error('Error:', error);
      setError(error.message); // Set error message
      setModalOpen(true); // Open the modal
    }
  };

  

  return (
    <div className="main">
      <Container className="w-75">
        <Row className="justify-content-center">
          <Col xs="auto" className="h5 text-danger fw-bold">
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>

            Demo For Voting
          </Col>
        </Row>
           <br></br>
        <form onSubmit={handleSubmit}>
          <Row>
            <Col xs="6" className="fw-bold">Enter Your Mobile Number</Col>
            <Col xs="6">
              <Input
                style={{ border: "2px solid black" }}
                type="number"
                name="username"
                value={formData.username}
                onChange={handleInputChange}
                required // Added required attribute here
              />
            </Col>
          </Row>
          <Row className="my-2">
            <Col xs="6" className="fw-bold">Enter 4-Digit Pin</Col>
            <Col xs="6">
              <Input
                style={{ border: "2px solid black" }}
                type="password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                required // Added required attribute here
              />
              <br></br>
              <Button
                style={{ border: "2px solid black" }}
                type="submit"
                className="btn btn-success fw-bold"
              >
                Ok
              </Button>
              <br></br>
            </Col>
          </Row>



          <Row className="my-2 justify-content-center">
            <Col xs="6" lg="6">
            <br></br>
              <Link to="trackvotes" className="w-100 btn btn-outline-dark fw-bold " style={{ backgroundColor: "#ffaec9", border: "2px solid black" }}>Track My Vote</Link>
            </Col>
          </Row>
          <Row className="my-2 justify-content-center">
            <Col xs="6" lg="6">
              <Link to="bogusvotingform" className="w-100 btn btn-outline-dark fw-bold" style={{ backgroundColor: "#fff200", border: "2px solid black" }}>Track Bogus Vote</Link>
            </Col>
          </Row>


          <Row className="my-2 justify-content-center" >
          <Col xs="auto" lg="auto" >
            <iframe width="330" height="185" src="https://www.youtube.com/embed/WccBJ1KMcoQ?si=blhuwvf1ssEN38Ij" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"  allowfullscreen></iframe>
            </Col>
          </Row>



          <Row className="my-2 justify-content-center">

          <Col xs="auto" lg="auto" >
            <iframe width="330" height="185" src="https://www.youtube.com/embed/bdelYYoB400?si=17JutAN90yu3tpYo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"  allowfullscreen></iframe>
            </Col>

          <Col xs="auto" lg="auto" >
            <iframe width="330" height="185" src="https://www.youtube.com/embed/dTTB8rhPjxA?si=tF_Ad3uO3dElU9fB" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"  allowfullscreen></iframe>
            </Col>
          </Row>



          <Row className="my-2 justify-content-center">
            <Col xs="auto" lg="auto" className="fw-bold">
              Email: ovmsystemsymbol@gmail.com
            </Col>
          </Row>

        </form>
      </Container>
      <ErrorModal isOpen={modalOpen} errorMessage={error} toggle={toggleModal} />
    </div>
  );
};

export default Index;
