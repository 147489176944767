import React, { useEffect, useState, useRef } from "react";
import { Col, Container, Row } from "reactstrap";
import search from "../../images/search.jpg";
import { useLocation } from "react-router-dom";

const Index = () => {
  const location = useLocation();
  const data = location.state?.data;
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [matchedPhoneNumber, setMatchedPhoneNumber] = useState("");
  const containerRef = useRef(null);

  useEffect(() => {
    // Scroll to the matched element when isSearchClicked becomes true
    if (isSearchClicked && containerRef.current) {
      const matchedElement = containerRef.current.querySelector(
        `[data-phone="${matchedPhoneNumber}"]`
      );
      if (matchedElement) {
        matchedElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [isSearchClicked, matchedPhoneNumber]);

  const handleSearchClick = () => {
    setIsSearchClicked(!isSearchClicked);
    // You can set the matched phone number here
    setMatchedPhoneNumber(data?.phoneno);
  };

  return (
    <Container className="fw-bold">
      <Row>
        <h4 className="text-center">Voter's Book List</h4>
      </Row>
      <Row>
        <Col xs="8" lg="8" className="text-end">
          Voter Id No.
        </Col>
        <Col xs="4" lg="4" className="text-start">
          <img
            src={search}
            alt="Profile Icon"
            className="img-fluid"
            onClick={handleSearchClick}
          />
        </Col>
      </Row>

      <Row>
        <Col xs="3" lg="3">
          Serial No.
        </Col>
        <Col xs="9" lg="9">
          Voter Id No. List
        </Col>
      </Row>
      <div ref={containerRef}>
        {data &&
          data.voterPhoneNos.map((phoneNumber, index) => (
            <Row
              key={index}
              data-phone={phoneNumber}
              style={{
                backgroundColor:
                  matchedPhoneNumber === phoneNumber && isSearchClicked
                    ? "#fff200"
                    : ""
              }}
            >
              <Col xs="3" lg="3">{index + 1}</Col>
              <Col xs="3" lg="3">{phoneNumber}</Col>
            </Row>
          ))}
      </div>
    </Container>
  );
};

export default Index;
