import { getLocalStorageItem } from "./LocalStorage";
const apiCaller = async (url, method = 'GET', data = null) => {

const token = getLocalStorageItem("userVerfier");
 
    try {
      const options = {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          ...(token && { 'Authorization': `Bearer ${token}` }),
        },
        body: data ? JSON.stringify(data) : null,
      };  
      
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const responseData = await response.json();
      return responseData;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };
  
  export default apiCaller;
  