// localStorage.js

// Function to set data in local storage
export const setLocalStorageItem = (key, value) => {
    
    localStorage.setItem(key, JSON.stringify(value));
  };
  
  // Function to get data from local storage
  export const getLocalStorageItem = (key) => {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  };
  
  // Function to update data in local storage
  export const updateLocalStorageItem = (key, updatedValue) => {
    const existingData = getLocalStorageItem(key);
    if (existingData) {
      setLocalStorageItem(key, { ...existingData, ...updatedValue });
    }
  };
  
  // Function to delete data from local storage
  export const deleteLocalStorageItem = (key) => {
    localStorage.removeItem(key);
  };
  