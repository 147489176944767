import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";

import "./index.css";
import { RouterProvider, createBrowserRouter  } from "react-router-dom";

import Layout from "./Layout";
import Login from "./pages/Login";
import BogusVotingForm from "./pages/BogusVotingForm";
import DemoVoting from "./pages/DemoVoting";
import TrackVotes from "./pages/TrackVotes";
import Result from "./pages/Result";
import TrackBogusVotes from "./pages/TrackBogusVotes";

const App = () => {


  const [isLoggedIn,setIsLoggedIn] = useState(false);
  const [profileData,setProfileData] = useState({
    votername:"",
    voterid:"",
    voteraddress:"",
    constituencyno:"",
  });

  
  useEffect(() => {
    // Check if user is logged in based on local storage
    const storedIsLoggedIn = localStorage.getItem("isLoggedIn") === true;
    setIsLoggedIn(storedIsLoggedIn);

    // Retrieve profile data from local storage
    const storedProfileData = JSON.parse(localStorage.getItem("profileData"));
    if (storedProfileData) {
      setProfileData(storedProfileData);
    }
  }, []);

  useEffect(() => {
    // console.log(profileData);
    // Store profile data in local storage whenever it changes
    localStorage.setItem("profileData", JSON.stringify(profileData));
  }, [profileData]);

  useEffect(()=>{
    // console.log(profileData)
  },[profileData])



  const commonVariable = {
    isLoggedIn,
    setIsLoggedIn,
    profileData,
    setProfileData,
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout commonVariable={commonVariable} />,
      children: [
        { index: true, element: <Login commonVariable={commonVariable}/> },
        { path: "demovoting", element: <DemoVoting commonVariable={commonVariable}/> },
        { path: "bogusvotingform", element: <BogusVotingForm commonVariable={commonVariable}/> },
        { path: "trackvotes", element: <TrackVotes commonVariable={commonVariable}/> },
        { path: "trackbogusvote", element: <TrackBogusVotes commonVariable={commonVariable}/> },
        { path: "result", element: <Result commonVariable={commonVariable}/> },
      ],
    },
  ]);

  return (
    <React.Fragment>
      <RouterProvider router={router} />
    </React.Fragment>
  );
};

ReactDOM.createRoot(document.getElementById("root")).render(<App />);
