export const consts = {
    siteUrl:"https://ovmsystem.com:9023/",
    // siteUrl:"http://localhost:9023/",
    api:{
        authenticate:"authenticate",
        checkUnique:"useraccess/checkuniquecode",
        addVoting:"useraccess/savevotinginfo",
        getOtp:"useraccess/getuserotp",
    }
}
