import React, { useEffect, useState, useRef } from "react";
import { Col, Container, Row } from "reactstrap";
import search from "../../images/search.jpg";
import lotus from "../../images/lotus.png";
import bicycle from "../../images/bicycle.png";
import sweep from "../../images/sweep.png";
import hand from "../../images/hand.jpg";
import { useLocation } from "react-router-dom";

const Index = (props) => {
  const images = {
    "0001": { image: lotus, name: "Shah" },
    "0002": { image: hand, name: "Hamza" },
    "0003": { image: sweep, name: "Gaurav" },
    "0004": { image: bicycle, name: "Bano" },
  };

  const location = useLocation();
  const data = location.state?.data;
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [searchedItemIndex, setSearchedItemIndex] = useState(null);
  const searchRef = useRef(null);

  const handleSearchClick = () => {
    setIsSearchClicked(!isSearchClicked);
  };

  useEffect(() => {
    if (isSearchClicked && searchedItemIndex !== null) {
      const itemRef = document.getElementById(`searchedItem-${searchedItemIndex}`);
      if (itemRef) {
        itemRef.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [isSearchClicked, searchedItemIndex]);

  useEffect(() => {
    if (data) {
      const index = data.electionResultSubResponses.findIndex(
        (item) =>
          item.listOfUesrGeneratedTokens.includes(data.usergeneratedtoken)
      );
      setSearchedItemIndex(index);
    }
  }, [data]);

  return (
    <Container>
      <Row>
        <h4 className="text-center fw-bold">Result List's of votes</h4>
      </Row>
      <Row>
        <Col xs="8" lg="8" className="text-end fw-bold">
          To track my casted vote click on search icon
        </Col>
        <Col xs="4" lg="4" className="text-start">
          <img
            src={search}
            alt="Profile Icon"
            className="img-fluid"
            onClick={handleSearchClick}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        {data?.electionResultSubResponses.map((items, index) => {
          return (
            <Col xs="3" lg="3" key={index} className="text-center">
              <img
                src={images[items.partyNo].image}
                alt="Party Icon"
                className="img-fluid"
              />
              <br />
              <div className="fw-bold text-primary mb-1">
                {images[items.partyNo].name}
              </div>
              <br />
    
              <span className="fw-bold text-center w-100">Votes:</span> <br />
              <div style={{ border: "2px solid black" }}>
                {items.noOfPartyVotes}
              </div>
              <Row className="mt-3">
                {items.listOfUesrGeneratedTokens.map((item, key) => {
                  return (
                    <Col
                      xs="12"
                      style={{
                        backgroundColor: `${
                          isSearchClicked && data.usergeneratedtoken === item
                            ? "#fff200"
                            : ""
                        }`,
                      }}
                      key={key}
                      id={`searchedItem-${index}`}
                      ref={
                        isSearchClicked && index === searchedItemIndex
                          ? searchRef
                          : null
                      }
                    >
                      {item}
                    </Col>
                  );
                })}
              </Row>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default Index;
