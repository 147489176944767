import React, { useState } from "react";
import { Button, Col, Container, Input, Row } from "reactstrap";
import "../Login.css";
import { setLocalStorageItem } from "../../helpers/LocalStorage";
import { useNavigate } from "react-router-dom";
import apiCaller from "../../helpers/ApiCaller";
import { consts } from "../../helpers/const";
import ErrorModal from '../ErrorModal';

const Index = ({ commonVariable }) => {
  const navigate = useNavigate();
  const { setIsLoggedIn } = commonVariable;

  // Define state variables for username and password
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    key: "BogusVotePage",
  });

  const [error, setError] = useState(null); // State to store error message
  const [modalOpen, setModalOpen] = useState(false); // State to manage modal visibility


  // Function to execute Api Calling
  const sendOtp = async () => {
    try {
      // Ensure key is set in the form data
      const requestData = { ...formData, key: formData.key || "BogusVotePage" };

      const response = await apiCaller(
        consts.siteUrl + consts.api.authenticate,
        "POST",
        requestData
      );

      const responseData = response;
      if (responseData) {
        if(response.response=== 'Bad Credentials'){
          setError("Wrong user name or pin"); 
          setModalOpen(true);
        }else{
        setIsLoggedIn(true);
        setLocalStorageItem("userData", response);
        setLocalStorageItem("isLoggedIn", true);
        navigate("/trackbogusvote",{ state: { data: response } });
      }}
    
    }
    catch (error) {
      console.error('Error:', error);
      setError(error.message); 
      setModalOpen(true);
       }
  };
  // Event handler to update state when input fields change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Event handler to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    sendOtp();

    // Here you can perform actions like login validation, API calls, etc.
    console.log("Submitted data:", formData);
    // Reset form fields
    setFormData({
      username: "",
      password: "",
    });
  };

    // Function to close the modal
    const toggleModal = () => {
      setModalOpen(!modalOpen);
    };

  return (
    <div className="main">
      <Container className="w-75">
        <Row className="justify-content-center">
          <Col xs="auto" className="h5 text-danger fw-bold">
           Track Bogus Vote
          </Col>
        </Row>
        <form onSubmit={handleSubmit}>
          <Row>
            <Col xs="6" className="fw-bold">
              Enter Your Mobile Number
            </Col>
            <Col xs="">
              <Input
                style={{ border: "2px solid black" }}
                type="text"
                name="username"
                value={formData.username}
                onChange={handleInputChange}
                required // Added required attribute here
              />
            </Col>
          </Row>
          <Row className="my-2">
            <Col xs="6" className="fw-bold">
              Enter 4-Digit Pin
            </Col>
            <Col xs="6">
              <Input
                style={{ border: "2px solid black" }}
                type="password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                required // Added required attribute here
              />
              <br></br>
              <Button
                style={{ border: "2px solid black" }}
                type="submit"
                className="btn btn-success fw-bold"
              >
                Ok
              </Button>
            </Col >
  
              
            </Row>
        </form>
      </Container>

      <ErrorModal
        isOpen={modalOpen}
        errorMessage={error}
        toggle={toggleModal}
      />
    </div>
  );
};

export default Index;
