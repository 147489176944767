import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Input,
  Row
} from "reactstrap";
import profileIcon from "../../images/images.jpeg";
import lotus from "../../images/lotus.png";
import bicycle from "../../images/bicycle.png";
import sweep from "../../images/sweep.png";
import hand from "../../images/hand.jpg";
import typeicon from "../../images/typeicon.png";
import { consts } from "../../helpers/const";
import apiCaller from "../../helpers/ApiCaller";
import ErrorModal from "../ErrorModal";

import "./demo.css";

import styled from "styled-components";
const StyledRadioInput = styled(Input)`
  // Apply green background color when checked
  &:checked {
    background-color: #4caf50; /* Green background color */
  }
  // Apply black border
  border: 2px solid black; /* Black border */
`;

const Index = ({ commonVariable }) => {
  const { profileData } = commonVariable; // Destructure profileData from commonVariable
  const navigate = useNavigate();

  const [symbolCode, setSymbolCode] = useState("");
  const [otp, setOtp] = useState("");
  const [checkAvailable, setCheckAvailable] = useState(false);
  const [otpIsLoading, setOtpIsLoading] = useState(false);
  const [selectedCandidateId, setSelectedCandidateId] = useState(null);
  const [error, setError] = useState(null); // State to store error message
  const [modalOpen, setModalOpen] = useState(false); // State to manage modal visibility

  // const renderModal = (message) => {
  //   debugger
  //   <PopupModal message={message} />;
  // };

  const handleInputChange = (event) => {
    

   
  const inputValue = event.target.value.replace(/[#^%,.\s]/g, '').slice(0, 10);

    
    // Update the state
    setSymbolCode(inputValue);
  };

  const handleCandidateSelection = (candidateId) => {
    setSelectedCandidateId(candidateId);
  };
  // Function to close the modal
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleCheckAvailability = async () => {
    if (symbolCode === "") {
      setError("Please fill the code");
      toggleModal();
      return;
    }

    // Construct the URL with parameters
    const apiUrl = `${
      consts.siteUrl + consts.api.checkUnique
    }?userGeneratedToken=${symbolCode}&constituencyNo=00X`;
    try {
      const response = await apiCaller(apiUrl, "POST");
      if (response) {
        if(response.response==="Not Available"){
          setError("Not Available");
          toggleModal();
          return
        }
        setCheckAvailable(true);
      }
    } catch (error) {
      setError(error);
      toggleModal();
    }
  };

  const handleOtpInputChange = (event) => {
    setOtp(event.target.value);
  };

  const handlesendOtp = async () => {
    setOtpIsLoading(true);
    const apiUrl = `${consts.siteUrl + consts.api.getOtp}`;
    try {
      const response = await apiCaller(apiUrl, "POST");
      if (response) {
        setError("Otp Sent");
        toggleModal();
      }
    } catch (error) {
      setError(error);
      toggleModal();
    }
  };
  const addVoting = async () => {
    if (!selectedCandidateId) {
      setError("Please select a candidate.");
      toggleModal();
      return;

    }
  if (!symbolCode) {
    setError("Please fill the symbol code");
    toggleModal();
    return;
  }
  
    const reqObj = {
      // otp: otp,
      voterid: profileData.voterid,
      voterpartyno: selectedCandidateId, // This value is now ensured to be present
      generatedvotertoken: symbolCode,
      constituencyareano: profileData.constituencyno,
    };
  
    const apiUrl = `${consts.siteUrl + consts.api.addVoting}`;
    
    try {
      const response = await apiCaller(apiUrl, "POST", reqObj);
      if (response) {
        setError(response.response);
        toggleModal();
        if (response.response=== "Vote Registered")
        setTimeout(() => {
          navigate("/");
        }, 3000); // 3 seconds delay
      }
    } catch (error) {
      setError(error);
      toggleModal();
    }
  };

  return (
    <>
      <Container
        className="py-2 px-1  demovoting-container my-5"
        style={{ border: "2px solid black" }}
      >
        <Row className="mx-0 justify-content-center mb-2">
          <Col
            lg="auto"
            xs="auto"
            className="px-5 py-2 rounded fw-bold"
            style={{ border: "2px solid black" }}
          >
            Demo Voting List
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs="5" className="text-center fw-bold">
            Voter Name
          </Col>
          <Col xs="5" className="text-center fw-bold">
            <Input
              style={{ border: "2px solid black" }}
              type="text"
              className="fw-bold"
              value={profileData.votername || ""}
              readOnly
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs="5" className="text-center fw-bold">
            Your Reference Id
          </Col>
          <Col xs="5" className="text-center fw-bold">
            <Input
              style={{ border: "2px solid black" }}
              type="text"
              className="fw-bold"
              value={profileData.voterid || ""}
              readOnly
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs="5" className="text-center fw-bold">
            Voter Address
          </Col>
          <Col xs="5" className="text-center fw-bold">
            <Input
              style={{ border: "2px solid black" }}
              type="text"
              className="fw-bold"
              value={profileData.voteraddress || ""}
              readOnly
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs="5" className="text-center fw-bold">
            Constituency Area No
          </Col>
          <Col xs="5" className="text-center fw-bold">
            <Input
              style={{ border: "2px solid black" }}
              type="text"
              className="fw-bold"
              value={profileData.constituencyno || ""}
              readOnly
            />
          </Col>
        </Row>
        <Row className="my-3 mx-0 ">
          <Col
            xs="1"
            className="text-center fw-bold d-flex align-items-center justify-content-center"
          >
            Sr No.
          </Col>
          <Col xs="3" className="text-center fw-bold">
            Candidate Photo
          </Col>
          <Col xs="3" className="text-center fw-bold">
            Candidate Name
          </Col>
          <Col xs="3" className="text-center fw-bold">
            Candidate Symbol
          </Col>
          <Col xs="2" className="text-center fw-bold">
            Select One
          </Col>
        </Row>
        {/* Second Row */}
        <Row className="mx-0">
          <Col
            xs="1"
            className="text-center fw-bold d-flex align-items-center justify-content-center"
          >
            1
          </Col>
          <Col xs="3" className="text-center">
            <img src={profileIcon} alt="Profile Icon" className="img-fluid" />
          </Col>
          <Col
            xs="3"
            className="fw-bold text-center fw-bold d-flex align-items-center justify-content-center"
          >
            Shah
          </Col>
          <Col xs="3" className="text-center">
            <img src={lotus} alt="Profile Icon" className="img-fluid" />
          </Col>
          <Col
            xs="2"
            className="text-center fw-bold d-flex align-items-center justify-content-center"
          >
            {" "}
            <StyledRadioInput
              type="radio"
              name="candidate"
              onClick={() => handleCandidateSelection("0001")}
              required // Add required attribute here
            />
          </Col>
        </Row>
        {/* Third Row */}
        <Row className="mx-0">
          <Col
            xs="1"
            className="text-center fw-bold d-flex align-items-center justify-content-center"
          >
            2
          </Col>
          <Col xs="3" className="text-center">
            <img src={profileIcon} alt="Profile Icon" className="img-fluid" />
          </Col>
          <Col
            xs="3"
            className="fw-bold text-center fw-bold d-flex align-items-center justify-content-center"
          >
            Hamza
          </Col>
          <Col xs="3" className="text-center">
            <img src={hand} alt="Profile Icon" className="img-fluid" />
          </Col>
          <Col
            xs="2"
            className="text-center fw-bold d-flex align-items-center justify-content-center"
          >
            {" "}
            <StyledRadioInput
              type="radio"
              name="candidate"
              onClick={() => handleCandidateSelection("0002")}
              required // Add required attribute here
            />
          </Col>
        </Row>

        {/* forth Row */}
        <Row className="mx-0">
          <Col
            xs="1"
            className="text-center fw-bold d-flex align-items-center justify-content-center"
          >
            3
          </Col>
          <Col xs="3" className="text-center">
            <img src={profileIcon} alt="Profile Icon" className="img-fluid" />
          </Col>
          <Col
            xs="3"
            className="fw-bold text-center fw-bold d-flex align-items-center justify-content-center"
          >
            Gaurav
          </Col>
          <Col xs="3" className="text-center">
            <img src={sweep} alt="Profile Icon" className="img-fluid" />
          </Col>
          <Col
            xs="2"
            className="text-center fw-bold d-flex align-items-center justify-content-center"
          >
            {" "}
            <StyledRadioInput
              type="radio"
              name="candidate"
              onClick={() => handleCandidateSelection("0003")}
              required // Add required attribute here
            />
          </Col>
        </Row>

        {/* Fifth Row */}
        <Row className="pb-5 mx-0">
          <Col
            xs="1"
            className="text-center fw-bold d-flex align-items-center justify-content-center"
          >
            4
          </Col>
          <Col xs="3" className="text-center">
            <img src={profileIcon} alt="Profile Icon" className="img-fluid" />
          </Col>
          <Col
            xs="3"
            className="fw-bold text-center fw-bold d-flex align-items-center justify-content-center"
          >
            Bano
          </Col>
          <Col xs="3" className="text-center">
            <img src={bicycle} alt="Profile Icon" className="img-fluid" />
          </Col>
          <Col
            xs="2"
            className="text-center fw-bold d-flex align-items-center justify-content-center"
          >
            <StyledRadioInput
              type="radio"
              name="candidate"
              onClick={() => handleCandidateSelection("0004")}
              required // Add required attribute here
            />
          </Col>
        </Row>

        {/* Rest of your code for candidate rows */}
      </Container>

      <Container
        className="py-2 px-1 rounded-2 demovoting-container my-5"
        style={{ border: "2px solid black" }}
      >
        <Row className="mx-0">
        <Col xs="2">
    <img src={typeicon} alt="Profile Icon" className="img-fluid"  />
  </Col>
          <Col xs="2" className="fw-bold">
            Genrate Symbol Code
          </Col>
          <Col xs="3">
            <Input
              style={{ border: "2px solid black" }}
              type="text"
              className="fw-bold"
              value={symbolCode}
              onChange={handleInputChange}
            />
          </Col>
          <Col xs="5">
          <Col xs="true" className="d-flex align-items-center">
    
          {checkAvailable ? (
              <>
                
                <Button className="btn btn-info" style={{ padding: "5px 10px" }} onClick={handleCheckAvailability}>
                Check Available
                </Button>
                <div className="rounded-circle bg-success" style={{ border: "2px solid black", width: "25px", height: "25px", marginRight: "1px", marginLeft: "3px" }}></div>
              </>
            ) : (
              <>
              <Button className="btn btn-info" style={{ padding: "5px 5px" }} onClick={handleCheckAvailability}>
                Check Available
              </Button>
              <div className="rounded-circle" style={{ border: "2px solid black", width: "25px", height: "25px", marginRight: "1px", marginLeft: "3px" }}></div></>

            )}
</Col>
</Col>
        </Row>
      </Container>

        {checkAvailable && (
          <Row className="mx-0 text-center fw-bold">
            <Col>
              <Button onClick={addVoting}>OK</Button>
            </Col>
          </Row>
        )}


      <ErrorModal
        isOpen={modalOpen}
        errorMessage={error}
        toggle={toggleModal}
      />
    </>
  );
};

export default Index;
